/* TOOLS.MIXINS */

@use "sass:math";
@use "sass:list";

@import "tools.functions";

// Font Icons
@mixin font-icon {
	// use !important to prevent issues with browser extensions that change fonts
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Breakpoint mixin
// Uses $breakpoints variable set in _config.scss file
@mixin bp($size) {

	@if (type-of($size) == "number") {
		@media (min-width: $size) {
			@content;
		}
	} @else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (min-width: $start) {
					@content;
				}
			}
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

@mixin bp-below($size) {

	@if (type-of($size) == "number") {
		@media (max-width: #{em($size - 1px)}) {
			@content;
		}
	} @else if (type-of($size) == "string") {
		@each $breakpoint in $breakpoints {
			// Get the name of the breakpoint.
			$alias: nth($breakpoint, 1);

			@if ($size == $alias) {
				$start: bp-config-value($breakpoint, "start");

				@media (max-width: #{$start - 1px}) {
					@content;
				}
			}
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
	}
}

// Positioning
@mixin abs-center {
	position: absolute;
	right: 50%;
	top: 50%;
	transform: translate(50%, -50%);
}

@mixin abs-center-h {
	position: absolute;
	right: 50%;
	transform: translateX(50%);
}

@mixin abs-center-v {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

// Border radius

@mixin radius {
	border-radius: $radius;
	overflow: hidden;
}

// Play Button

@mixin playbutton {
	@include abs-center;
	width: 50px;
	height: 35px;
	background-color: $brand;
	@include link-transition;
	@include radius;

	&::before {
		@include pseudo-element;
		left: 18px;
		top: 10px;
		border-width: 8px 16px;
		border-color: transparent;
		border-left-color: $white;
		border-style: solid;
	}

	@include bp('medium') {
		width: 60px;
		height: 45px;

		&::before {
			left: 22px;
			top: 13px;
			border-width: 10px 20px;
		}
	}
}

// Layout
@mixin layout-split($side, $split, $width: $layout-wrapper-max-width, $gutter: $bsu) {
	// $side should be either left or right
	// $split should be a percentage

	// Example use:
	// @include layout-split(left, 40%);

	// To be used elements that need to extend to the edge of the page,
	// so it can't be wrapped in a layout-wrapper, but with content that
	// needs to align with indented content as though it were in a wrapper.

	// Because it uses % padding, it's important that any element using this
	// sits within a container with a specified width. That width should be
	// the same as $split as a proportion of the screen width

	$counter-split: 100% - $split;
	$ratio: math.div($counter-split, $split);

	$growthRate: #{(math.div(($ratio - 1), 2) + 1) * 100%};
	$normaliser: #{math.div($width, 2) - $gutter};

	@include bp($width) {
		padding-#{$side}: calc(#{$growthRate} - #{$normaliser});
	}
}

/**
 * The "RAM" pattern is Repeat Auto Minmax, which allows items in a grid
 * to reflow automatically with the goal of putting as many items as
 * possible on a row while also never going below a minimum size
 */
@mixin grid-ram($size) {
	grid-template-columns: repeat(auto-fit, minmax($size, 1fr));
}

// Transition
/**
 * Applies a transition in a consistent way.
 *
 * $props can either be a single property or a ", " separated list
 * of property strings.
 */
@mixin link-transition($propString: all, $speed: 0.25s) {
	$props: string-split($propString);
	$transitions: props-to-transitions($props, $speed);

	/* autoprefixer: ignore next */
	transition: $transitions;

	@media (prefers-reduced-motion) {
		transition: none;
	}
}

@mixin scroll-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		.js &:not([data-scroll-appear="true"]) {
			@content;
		}
	}
}

// Clearfix
@mixin clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin remove-clearfix {
	&::after {
		display: none;
		clear: none;
	}
}

// Pseudo Element
@mixin pseudo-element {
	content: '';
	display: block;
	position: absolute;
}

// Reliable Flexbox fallback mixin
// 1. Unsupported
// 2. Old - iOS 6-, Safari 3.1-6
// 3. Old - Firefox 19- (buggy but mostly works)
// 4. Tweener - IE 10
// 5. New - Chrome
// 6. New, Spec - Opera 12.1, Firefox 20+
@mixin flex-extend {
	display: block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	/* autoprefixer: ignore next */
	display: flex;
}

@mixin flex-wrap {
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	/* autoprefixer: ignore next */
	flex-wrap: wrap;
}

@mixin flex-nowrap {
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	/* autoprefixer: off */
	flex-wrap: nowrap;
}

// Placeholder Text
@mixin placeholder-text ($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}
	&:-moz-placeholder {
		color: $color;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
}

// Mixin to add square dimensions to an element i.e. the same width and height
// Example: Create a square 40em high and wide
// @include tb_square ( 40em );
@mixin square($size) {
	width: $size;
	height: $size;
}

// Mixin to add circle dimensions to an element i.e. adds square dimensions and border-radius of (dimensions / 2)
// Example: Create a circle 40em high and wide
// @include tb_square ( 40em );
@mixin circle($size) {
	@include square($size);
	border-radius: math.div($size, 2);
}

// UI Border mixin
$ui-border: 1px solid $base-ui-color;

@mixin ui-border($side) {
	@if ($side == 'all') {
		border: $ui-border
	} @else if ($side == 'top') {
		border-top: $ui-border;
	} @else if ($side == 'right') {
		border-right: $ui-border;
	} @else if ($side == 'bottom') {
		border-bottom: $ui-border;
	} @else if ($side == 'left') {
		border-left: $ui-border;
	}
}

// Resets
@mixin button-reset {
	// Used to reset styles on <button> elementss
	// This can be used for interactive elements that aren't links
	background: transparent;
	border: none;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
}

@mixin link-plain {
	@include link-transition;
	color: inherit;
}

// Forms
@mixin form-input {
	// iOS zooms in unless font size in inputs is at least 16px
	@include font-base-16;
	display: block;
	width: 100%;
	// 11px top and bottom padding makes them 48px tall, same as buttons
	padding: 11px 0.65em;
	border: 1px solid #E6E7E8;
	border-radius: 4px;
	background: #fdfdfd;
	transition: 0.3s;
	outline: none;

	&:focus {
		border: 1px solid $brand;
	}

	&.input-validation-error {
		border-color: $error;
		background: rgba(251, 194, 196, 0.1);
	}
}

$outline-default: #4d90fe;
@mixin focus-outline($color: $outline-default) {
	// Replicate Chrome's default focus style
	outline-color: $color;
	@if ($color == $outline-default) {
		@supports (outline-color: -webkit-focus-ring-color) {
			outline-color: -webkit-focus-ring-color;
		}
	}

	// IE11 and Edge don't support outline-style: auto
	outline-style: solid;
	outline-width: 2px;
	@supports(outline-style: auto) {
		outline-style: auto;
	}
}
