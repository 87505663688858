// COMPONENT.HERO

$hero-bp: "large";

.c-hero {
	position: relative;
	margin-bottom: $lsu;

	@include bp("medium") {
		margin-bottom: $lsu * 1.5;
	}

	@include bp("large") {
		margin-bottom: $hsu;
	}
}

.c-hero__figure {
	@include figure;
}

///////////
// IMAGE //
///////////

.c-hero__image {}

.c-hero__image--mobile {
	@include bp($hero-bp) {
		display: none;
	}
}

.c-hero__image--desktop {
	@include bp-below($hero-bp) {
		display: none;
	}
}

.c-hero__heading {
	position: absolute;
	top: calc(50% + 32px);
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;

	@include font-heading-36b;
	color: $white;
	text-shadow: 0 0 5px $black;
}

///////////
// VIDEO //
///////////

.c-hero__video {
	display: none;

	@include bp("medium") {
		display: block;
		width: 100%;
		max-height: 80vh;
		object-fit: cover;

		~ .c-hero__image {
			display: none;
		}
	}
}

/////////////
// PRIMARY //
/////////////

.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;

	@include flex-extend;
	flex-direction: column-reverse;

	@include bp($hero-bp) {
		flex-direction: row;
		align-items: stretch;
	}

	.c-hero__body {
		@include flex-extend;
		align-items: center;
		padding: $bsu;

		@include bp($hero-bp) {
			width: 100%;
			min-height: 200px;
			.c-hero--has-image & {
				width: 50%;
				min-height: 540px;
				padding-left: 0;
				padding-right: 0;
			}

			// Offset from image
			margin-bottom: $lsu;
		}
	}

	.c-hero__body-content {
		flex-direction: column;

		padding: $bsu;
		@include bp($hero-bp) {
			@include layout-wrapper;
			.c-hero--has-image & {
				max-width: none;
				padding: 0;

				@include bp-below($layout-wrapper-max-width) {
					padding-left: $bsu;
				}
				@include layout-split(left, 50%);
				padding-right: $lsu;
			}
		}
	}

	.c-hero__title {
		@include font-heading-36b;
		position: relative;
		z-index: 2;
		margin-bottom: $ssu;

		@include bp("medium") {
			margin-bottom: $bsu;
		}

		@include bp($hero-bp) {
			@include font-heading-60b;
			margin-bottom: $lsu;
		}
	}

	.c-hero__text {
		@include font-base-18;

		@include bp($hero-bp) {
			flex-grow: 1;
		}
	}

	.c-hero__date {
		@include font-base-12;
		margin-bottom: 2em;
	}

	.c-hero__scroll-icon {
		margin-top: $lsu;
		color: $red;
		text-align: center;
		font-size: 24px;
		display: none;

		@include bp($hero-bp) {
			display: block;
		}
	}

	.c-hero__figure {
		@include figure;

		flex-grow: 1;
		position: relative;
		overflow: hidden;
		max-height: 80vh;
		max-height: min(400px, 80vh);

		border-radius: 0 0 0 4px;

		@include bp($hero-bp) {
			max-height: none;
		}
	}

	.c-hero__image {
		display: block;
		width: 100%;

		@include bp($hero-bp) {
			height: 100%;
			padding: 0 !important;
			object-fit: cover;
		}
	}
}

///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: ($bsu * 3) 0 0; // Match .o-layout-page

	.c-hero__secondary-title {
		width: 100%;
		text-align: center;
	}

	.c-hero__secondary-title-text {
		display: block;
		color: $brand;
		margin-bottom: $bsu;

		@include bp($hero-bp) {
			padding: 0 20px;
			margin-bottom: 0;
		}
	}

	.c-hero__secondary-introduction {
		@include font-base-18;
		flex: 0 1 auto;

		@include bp($hero-bp) {
			margin-top: 4px; // Align better with secondary title
			margin-left: 40px;
		}
	}
}
