// OBJECT.FIT

@mixin fit {
	position: relative;
	display: block;
	width: 100%;
}

@mixin fit__image {
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.o-fit {
	@include fit;
}

.o-fit__image {
	@include fit__image;
}
