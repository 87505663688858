// CONTROL.NEWSLETTER

.c-newsletter {
	@include control;

	background: $grey--darkest;
	padding: $bsu;

	.c-form__field-label {
		color: $white;
	}
}
.c-footer {
	.c-newsletter {
		padding: $bsu 0;
	}
}

.c-newsletter__layout {
	@include layout-wrapper;
}

.c-newsletter__fields-wrap {
	display: flex;
	flex-direction: column;
	gap: $bsu;
	flex-wrap: wrap;
	width: 100%;
}

.c-newsletter__fields {
	@include list-reset;

	@include flex-extend;
	flex-direction: column;
	//margin-top: -$bsu;
	//margin-left: -$bsu;
	border-bottom: 0;
	gap: $bsu;
	width: 100%;

	@include bp("small") {
		flex-direction: row;
		flex-wrap: wrap;
	}

	@include bp("medium") {
		flex-wrap: nowrap;
	}

	@include bp("large"){
		width: auto;
	}
}

.c-newsletter__field,
.c-newsletter__submit {
	flex-grow: 1;

	//padding-top: $bsu;
	//padding-left: $bsu;

	@include bp("small") {
		width: 50%;
	}
	@include bp("medium") {
		width: auto;
	}
}

.c-newsletter__field {


	@include bp-below("small") {
		margin-bottom: 0;
	}
}

.c-newsletter__submit {
	@include flex-extend;
	align-items: flex-end;
	padding-left: 0;
	margin-bottom: $bsu;
}

.c-newsletter__submit-button {
	@include button;
	display: block;
}

.c-newsletter__heading {
	@include control__heading;

	color: $white;
}

.c-newsletter__message {
	color: $white;
}
