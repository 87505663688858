// CONTROL.FACTS-FIGURES

@use "sass:math";

$facts-figures-gap: $bsu;

.c-facts-figures {
	@include control;
	@include radius;

	padding: $bsu 0;
	@include bp("large") {
		padding: $lsu 0;
	}
}

.c-facts-figures__layout {
	@include layout-wrapper;

	.l-page--has-sidebar .l-page__main-controls & {
		@include bp($layout-page-bp) {
			padding: 0 $lsu;
		}
	}
}

.c-facts-figures__heading {
	@include control__heading;
}

.c-facts-figures__item-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	margin-left: -$facts-figures-gap - $bsu;
	margin-right: -$bsu;
	margin-top: $bsu - $facts-figures-gap;
	margin-bottom: $bsu;
	@include bp("large") {
		margin-top: $lsu - $facts-figures-gap;
		margin-bottom: $lsu;
	}
}

.c-facts-figures__item {
	display: flex;
	align-items: stretch;

	padding-left: $facts-figures-gap;
	padding-top: $facts-figures-gap;

	// Fake borders with offsets using absolutely positioned pseudoelements
	position: relative;
	&::before,
	&::after {
		content: "";
		position: absolute;
	}
	&::before {
		top: math.div($facts-figures-gap, 2);
		left: $facts-figures-gap + math.div($facts-figures-gap, 2);
		right: math.div($facts-figures-gap, 2);
		border-top: 1px solid rgba($white, 0.4);
	}
	&::after {
		left: math.div($facts-figures-gap, 2);
		top: $facts-figures-gap + math.div($facts-figures-gap, 2);
		bottom: math.div($facts-figures-gap, 2);
		border-left: 1px solid rgba($white, 0.4);
	}

	// Hide left and top dividers on first item
	&:first-child {
		&::before,
		&::after {
			display: none;
		}
	}

	// Alignment settings
	@include bp-below("medium") {
		width: 100%;
		// Hide left divider on the first item of each row
		&:nth-child(n+1)::after {
			display: none;
		}
	}
	@include bp("medium") {
		@include bp-below("large") {
			width: 50%;
			// Hide left divider on the first item of each row
			&:nth-child(2n-1)::after {
				display: none;
			}
		}
		// Hide top divider on first item of second column
		&:nth-child(2)::before {
			display: none;
		}
	}
	@include bp("large") {
		width: percentage(math.div(1, 3));

		// If there are only two items, make them 50% width
		&:first-child:nth-last-child(2) {
			&, ~ .c-facts-figures__item {
				width: 50%;
			}
		}

		// Hide left divider on the first item of each row
		&:nth-child(3n-2)::after {
			display: none;
		}
		// Hide top divider on the first item of third column
		&:nth-child(3)::before {
			display: none;
		}
	}

	@include scroll-appear {
		opacity: 0;
		transform: translateY(100px);
	}
	@include link-transition("opacity, transform", 0.6s);
}

.c-facts-figures__pod {
	display: flex;
	width: 100%;
	margin: $bsu;
	flex-direction: column;
	padding: $bsu;
	@include bp("large") {
		padding: $lsu;
	}

	@include link-transition("color, background-color");
}

.c-facts-figures__item-title {
	display: block;

	@include font-heading-36b;
	@include bp("large") {
		@include font-heading-60b;
	}
}

.c-facts-figures__item-text {
	flex-grow: 1;
	display: block;
	margin-top: $bsu;

	@include font-body-text;
}

.c-facts-figures__item-link {
	display: block;
	margin-top: $bsu;

	@include font-body-text;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		margin-left: $tsu;
	}
}
