﻿.c-pathway-carousel {
    margin-bottom: ($lsu * 2);

    @include bp("medium") {
        margin-bottom: $lsu;
    }

    header {
        height: 60px;
    }

    .flickity-prev-next-button {
        top: auto;
        bottom: -60px;
        margin: 0 0 -30px 0;

        @include bp("medium") {
            top: 0%;
            margin-top: -35px;
        }
    }

    .flickity-prev-next-button.previous {
        left: auto;
        right: 60px;
    }
}

.c-pathway-carousel__heading,
.c-pathway-carousel__link {
    display: inline-block;
}

.c-pathway-carousel__link {
    margin-left: $bsu;

    @include bp("small") {
        margin-left: $hsu;
    }
}

.c-pathway-carousel__item-list {
    height: auto;

    > div {
        height: 100%;
        width: 100%;

        @include bp("small") {
            width: calc(100% + 10px);
        }

        @include bp("large") {
            width: calc(100% + 20px);
        }
    }

    .c-pathway-carousel__item {
        width: 100%;
        height: auto;
        padding-right: 0;

        @include bp("small") {
            width: 50%;
            padding-right: $bsu;
        }

        @include bp("large") {
            width: 50%;
        }

        @include bp("extra-large") {
            width: 25%;
            padding-right: $lsu;
        }
    }
}

.c-pathway-carousel__item-logo-wrapper {
    height: 100px;
    margin-bottom: $bsu;

    .c-pathway-carousel__item-logo {
        display: block;
        width: 100%;

        img {
            width: 100%;
            height: 100px;
            object-fit: contain;
        }
    }
}

.c-pathway-carousel__item-icon-wrapper {
    height: 100px;
    margin-bottom: $bsu;

    .c-pathway-carousel__item-icon {
        display: block;
        width: 100%;
        max-width: 100px;
        max-height: 100px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100px;
            width: 100px;
            object-fit: contain;
        }
    }
}

.c-pathway-carousel__item-image-wrapper {
    height: 200px;
    margin-bottom: $bsu;
    overflow: hidden;

    .c-pathway-carousel__item-image {
        display: block;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }
}

.c-pathway-carousel__item-heading {
    @include font-heading-24b;
    margin-bottom: $bsu;
}

.c-pathway-carousel__item-text {
    @include font-base-14;
    margin-bottom: $bsu;
}

.c-pathway-carousel__item-link {
    @include link-transition;
}

.flickity-enabled:focus {
    outline: none;
}
