﻿// CONTROL.CTA

.c-cta {
	@include control;
}

.c-cta__layout {
	@include layout-wrapper;
}

.c-cta--dark {
	.c-cta__body {
		background: $grey--darkest;
	}
	.c-cta__heading,
	.c-cta__text{
		color: $white;
	}
}

.c-cta__body {
	position: relative;
	padding: $bsu;
	margin: $bsu 0;
	text-align: center;
	background-color: $grey--lightest;

	@include radius;

	> *:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}

	@include bp("medium") {

		.o-layout-main & {
			@include flex-extend;
			padding: ($bsu * 1.5) ($bsu * 2);
			align-items: center;
			text-align: left;

			&::before {
				right: auto;
				left: ($bsu * 2);
				transform: translateX(0);
			}
		}
	}

	@include bp("large") {

		.o-layout-main & {
			@include flex-extend;
			padding: ($bsu * 1.5) ($bsu * 4);
			align-items: center;
			text-align: left;

			&::before {
				left: ($bsu * 4);
			}
		}
	}
}

.c-cta__body-wrapper {
	margin-bottom: $bsu;

	@include bp("medium") {

		.o-layout-main & {
			flex: 1 1 auto;
			padding-right: $lsu;
			margin-bottom: 0;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta__heading {
	@include font-base-20b;
	margin-bottom: 0.5em;
	color: $body-color;

	@include bp("large") {
		@include font-base-28b;
	}
}

.c-cta__text {
	@include font-body-text;
	margin-bottom: 2em;
	color: $body-color;
}

.c-cta__link {
	@include button("medium");

	@include bp("medium") {
		white-space: nowrap;
	}
}
